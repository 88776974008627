import React, { useEffect, useState } from 'react';
import shortid from 'shortid';
let axios = require('axios');
let jsonpAdapter = require('axios-jsonp');

export default function ShoutHOC(gonationID, Component, props) {
  const HOC = props => {
    const [shoutData, setShoutData] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
      if (gonationID) {
        axios({
          url: `https://data.prod.gonation.com/profile/shoutsnew/${gonationID}`,
          adapter: jsonpAdapter,
        })
          .then(res => {
            setShoutData(res.data);
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    }, []);

    return !isLoading ? (
      <Component key={shortid.generate()} {...props} shoutData={shoutData} />
    ) : (
      ''
    );
  };

  return HOC;
}

// takes in gonation id and shout data
// this is a higher order component that gives any component shout data as a prop. by fetching the data for it.
