import { useContext } from 'react'
import { store } from '../Context/Store'
import useGetCities from './useGetCities'
import slugifyLower from '../helpers/slugifyLower'
/**
 *
 * returns object with location names and slug arrays
 * @example    const locationNamesAndSlugs = useLocationNamesAndSlugs()
 * @returns {Object} - {locationNames:[...names],locationSlugs:[..slugs]}
 *
 */

const useLocationNamesAndSlugs = () => {
  const cities = useGetCities()
  const {
    globalState: { siteData },
  } = useContext(store)

  const locationNames = siteData?.locationNames
    ? siteData?.locationNames
    : cities
  const locationSlugs = siteData?.locationSlugs
    ? siteData?.locationSlugs
    : siteData?.locations.map((location) => slugifyLower(location))

  return {
    locationNames,
    locationSlugs,
  }
}

export default useLocationNamesAndSlugs
