import React from 'react';
import { Flex, Link, Text } from 'theme-ui';
import GonationLogo from './GonationLogo';

export default function GonationLogoPowered() {
  return (
    <Flex className='gonationLogo' sx={styles.container}>
      <Link
        href='https://www.gonation.com'
        target='_blank'
        rel='noreferrer'
        className='gonationLogo'
        sx={styles.link}
      >
        <Text as='span' className='poweredByText' sx={styles.text}>
          Local Powered By &nbsp; <GonationLogo />
        </Text>
      </Link>
    </Flex>
  );
}

const styles = {
  container: {
    alignItems: 'center',
    justifyContent: 'center',
    whiteSpace: 'nowrap',
    width: '100%',
  },
  link: {
    width: '100%',
  },
  text: {
    width: 'fit-content',
    maxWidth: '230px',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '0.7rem',
    flexWrap: 'wrap',
    fontFamily: 'Arial, sans-serif',
    fontWeight: '200',
  },
  // logo: {
  //   maxWidth: '85px',
  //   width: '100%',
  // },
};
