import React from 'react'
import { Link } from '@theme-ui/components'
import generateLink from '../../helpers/generateLink'

export default function ExternalLink({
  ctaClassName,
  variant,
  ctaName,
  ctaLink,
  isMultiLocationSite,
  city,
}) {
  return (
    <Link
      className={`ctaButton ${ctaClassName ? ctaClassName : ''}`}
      variant={`${variant}.ctaButton`}
      href={generateLink(ctaLink, isMultiLocationSite, city)}
      target={'_blank'}
      rel='noreferrer'>
      {ctaName}
    </Link>
  )
}
