/**
 *
 * selects the title based on the string input
 *
 *
 * @param {string} title - Title text string
 * @param {array} businessName - businessName coming from GN
 *
 */

export default (text, aboutText) => {
  switch (text) {
    case '':
      return ''
    case 'none':
      return ''
    case 'default':
      return aboutText
    case 'about':
      return aboutText
    case text:
      return text
    default:
      break
  }
}
