import React from 'react'
import FacebookLogo from '../Icons/Facebook'
import GonationLogo from '../Icons/GoNationMan'
import InstagramLogo from '../Icons/Instagram'
import LinkedinLogo from '../Icons/Linkedin'
import PinterestLogo from '../Icons/Pinterest'
import Twitter from '../Icons/Twitter'
import YouTube from '../Icons/YouTube'

import { Flex, Link } from 'theme-ui'

export default function SocialIconsV1({ links, slug, variantName }) {
  const variant = variantName ? variantName : 'socialIconsV1'
  return (
    <Flex
      variant={`${variant}.socialIconsContainer`}
      className='socialContainer socialIconsContainer'>
      {links.facebook ? (
        <Link
          href={`${links.facebook}`}
          target='_blank'
          rel='noreferrer'
          className='socialLink facebook'>
          <FacebookLogo />
        </Link>
      ) : (
        ''
      )}
      {links.instagram ? (
        <Link
          href={`${links.instagram}`}
          target='_blank'
          rel='noreferrer'
          className='socialLink instagram'>
          <InstagramLogo />
        </Link>
      ) : (
        ''
      )}
      {links.linkedin ? (
        <Link
          href={`${links.linkedin}`}
          target='_blank'
          rel='noreferrer'
          className='socialLink linkedin'>
          <LinkedinLogo />
        </Link>
      ) : (
        ''
      )}
      {links.youtube ? (
        <Link
          href={`${links.youtube}`}
          target='_blank'
          rel='noreferrer'
          className='socialLink youtube'>
          <YouTube />
        </Link>
      ) : (
        ''
      )}
      {links.twitter ? (
        <Link
          href={`${links.twitter}`}
          target='_blank'
          rel='noreferrer'
          className='socialLink twitter'>
          <Twitter />
        </Link>
      ) : (
        ''
      )}
      {links.pinterest ? (
        <Link
          href={`${links.pinterest}`}
          target='_blank'
          rel='noreferrer'
          className='socialLink pinterest'>
          <PinterestLogo />
        </Link>
      ) : (
        ''
      )}

      {slug ? (
        <Link
          href={`https://www.gonation.com/place/${slug}/pulse`}
          target='_blank'
          rel='noreferrer'
          className='socialLink gonation'>
          <GonationLogo />
        </Link>
      ) : (
        ''
      )}
    </Flex>
  )
}
