import React from 'react'
import checkIfExternalLink from '../../helpers/checkIfExternalLink'
import ExternalLink from './ExternalLink'
import InternalLink from './InternalLink'

export default function LinkSelector(props) {
  const {
    variant,
    ctaName,
    ctaLink,
    isMultiLocationSite,
    city,
    ctaClassName,
  } = props
  return (
    <>
      {checkIfExternalLink(ctaLink) ? (
        <ExternalLink
          ctaClassName={ctaClassName}
          variant={`${variant}`}
          ctaName={ctaName}
          ctaLink={ctaLink}
          isMultiLocationSite={isMultiLocationSite}
          city={city}
        />
      ) : (
        <InternalLink
          ctaClassName={ctaClassName}
          variant={`${variant}`}
          ctaName={ctaName}
          ctaLink={ctaLink}
          isMultiLocationSite={isMultiLocationSite}
          city={city}
        />
      )}
    </>
  )
}
