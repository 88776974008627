/**
 *
 *  This component is for hero content that has been parsed and pulled form location index
 * so it ino longer using location index as it has ben filter and destructured to give extact props
 */

import React from 'react'
import { Flex, Heading, Text } from 'theme-ui'
import CloudinaryImage from '../../General/CloudinaryImage'
import Shout from '../../Shout/v1/Index'
import LinkSelector from '../../Links/LinkSelector'
import TextBodySelector from '../../General/TextBodySelector'
export default function HeroContent({
  logoHero,
  titles,
  subtitles,
  texts,
  desc,
  textType,
  ctaNames,
  ctaLinks,
  city,
  imageBaseUrl,
  isMultiLocationSite,
  imagePrefix,
  locationIndex,
  variantName,
  shoutData,
  shoutStyles,
}) {
  return (
    <Flex className='hero_content_container' variant={`${variantName}.content`}>
      {logoHero && (
        <CloudinaryImage
          variant={`${variantName}.logoHero`}
          className='logoHero'
          imageBaseUrl={imageBaseUrl}
          imagePrefix={imagePrefix}
        />
      )}

      {titles && (
        <Heading variant={`${variantName}.title`} as='h3' className='title'>
          {titles}
        </Heading>
      )}
      {subtitles && (
        <Heading
          variant={`${variantName}.subtitle`}
          as='h4'
          className='subtitle'
        >
          {subtitles}
        </Heading>
      )}
      {texts && (
        <TextBodySelector
          variantName={`${variantName}`}
          texts={texts}
          textType={textType}
          // restrictCharacters={restrictCharacters}
          desc={desc}
        />
        // <Text variant={`${variantName}.text`} as='p' className='text'>
        //   {texts}
        // </Text>
      )}

      {shoutData?.shout && (
        <Shout
          shoutData={shoutData}
          variantName={`${variantName}.shoutWidget`}
          sx={{
            ...shoutStyles,
          }}
        />
      )}

      {ctaNames && ctaLinks && (
        <LinkSelector
          variant={`${variantName}`}
          ctaName={ctaNames}
          ctaLink={ctaLinks}
          isMultiLocationSite={isMultiLocationSite}
          city={city}
        />
      )}
    </Flex>
  )
}
