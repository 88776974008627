import React from 'react'
import { Flex, Link, Heading, Text } from 'theme-ui'
import shortid from 'shortid'
import checkIfExternalLink from '../../../helpers/checkIfExternalLink'
import ImageSelector from '../../Images/ImageSelector'
import TextBodySelector from '../../General/TextBodySelector'

export default function ContentBox({
  variantName,
  poweredImages,
  locationIndex,
  image,
  ctaLink,
  ctaName,
  title,
  text,
  textType,
  subtitle,
  // date,
}) {
  return (
    <Flex
      key={shortid.generate()}
      variant={`${variantName}.box`}
      className='box'
      sx={{
        pointerEvents: ctaLink ? 'visible' : 'none',
      }}
    >
      {image && (
        <ImageSelector
          className='image'
          variant={`${variantName}.image`}
          image={image} // image from config
          poweredImages={poweredImages} // powered iamges object
          locationIndex={locationIndex}
          alt={`contentbox`}
        />
      )}
      {title && (
        <Heading variant={`${variantName}.title`} as='h3' className='title'>
          {title}
        </Heading>
      )}
      {subtitle && (
        <Heading
          variant={`${variantName}.subtitle`}
          as='h4'
          className='subtitle'
        >
          {subtitle}
        </Heading>
      )}
      {text && (
        <TextBodySelector
          variantName={`${variantName}`}
          texts={text}
          textType={textType}
        />
      )}

      {ctaLink && (
        <Link
          key={shortid.generate()}
          variant={`${variantName}.ctaLink`}
          className='ctaLink'
          sx={{
            pointerEvents: ctaLink ? 'visible' : 'none',
          }}
          href={ctaLink}
          target={`${ctaLink && checkIfExternalLink(ctaLink) ? '_blank' : ''}`}
          rel={`${ctaLink && checkIfExternalLink(ctaLink) ? 'noreferrer' : ''}`}
        >
          {ctaName ? ctaName : 'See more'}
        </Link>
      )}
    </Flex>
  )
}
