/**
 *
 * *This takes all the stories and filters the one it wants by id
 * Then returns and object of data for that story else return empy object
 *
 * @param {string} storyId - the id of the story from GN
 * @param {array} allGeneral - All Stories in an Array.
 * @param {boolean} isArrayWithinArray - (UnderWorks) nested block of arrays in arrays
 */

export default (storyId, allGeneral, isArrayWithinArray, size) => {
  if (storyId && allGeneral) {
    const story = allGeneral.filter(story => story.id === storyId)[0]; // !should only ever return one element in a array
    if (story) {
      // returns a object of the story data

      const createImagesArray = () => {
        return story.media.map(image => {
          if (image.cloudinaryId) {
            return `https://res.cloudinary.com/gonation/w_${
              size || 1800
            }/q_auto/f_auto/${image.cloudinaryId}`;
          } else {
            return '';
          }
        });
      };
      return {
        titles: story.title,
        subtitles: story.subtitle,
        texts:
          story.bodyHTML.replace(/\s/g, '') !== '<p></p>' ? story.bodyHTML : '', // regex replace needed to remove spaces and tabs
        textType: 'html', // needed to determine
        images: createImagesArray(),
        ctaNames: story.ctaLink.title,
        ctaLinks: story.ctaLink.url,
      };
    } else {
      console.log('Story Could Not Be Found By Id');
      return {};
    }
  } else {
    return {};
  }
};
