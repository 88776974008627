import React from 'react'
import { Text, Box } from '@theme-ui/components'
import textSelector from '../../helpers/textSelector'
import textSupressor from '../../helpers/textSupressor'

export default function TextBodySelector({
  texts,
  textType,
  variantName,
  restrictCharacters,
  desc,
}) {
  if (texts) {
    return (
      <>
        {texts && textType !== 'html' ? (
          <Text as='p' variant={`${variantName}.text`} className='text'>
            {textSupressor(textSelector(texts, desc), restrictCharacters)}
          </Text>
        ) : (
          ''
        )}

        {textType === 'html' && (
          <Box
            variant={'bodyHTMLStyles'}
            className='text'
            dangerouslySetInnerHTML={{ __html: texts }}
          />
        )}
      </>
    )
  } else {
    return ''
  }
}
