import React from 'react'
import { Image } from '@theme-ui/components'
export default function CloudinaryImage({
  imageBaseUrl,
  imagePrefix,
  ...rest
}) {
  return (
    <Image
      src={`${imageBaseUrl}/${imagePrefix}`}
      className='image'
      {...rest}
      alt='Imported Content'
    />
  )
}
