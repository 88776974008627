import { useContext } from 'react'
import { store } from '../Context/Store'
import useCurrentLocationIndex from './useCurrentLocationIndex'
import useGetCities from './useGetCities'
import slugifyLower from '../helpers/slugifyLower'
/**
 *
 * Converts CamelCase Into Sentence Case
 * @example    const locationNamesAndSlugs = useLocationNamesAndSlugs()
 * @returns {Object} - {currentLocationName:"",currentLocationSlug:""}
 *
 */

export const useCurrentLocationNameAndSlug = () => {
  const cities = useGetCities()

  const {
    globalState: { siteData },
  } = useContext(store)

  const index = useCurrentLocationIndex() // get current location index dependant on users current viewing apge

  const currentLocationName = siteData?.locationNames?.[index]
    ? siteData.locationNames?.[index]
    : cities?.[index]

  const currentLocationSlug = siteData?.locationSlugs?.[index]
    ? siteData?.locationSlugs?.[index]
    : slugifyLower(siteData?.locations?.[index ? index : 0])

  return {
    currentLocationName,
    currentLocationSlug,
  }
}

export default useCurrentLocationNameAndSlug
