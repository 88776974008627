import React from 'react';
import { Flex, Link, Heading, Text } from 'theme-ui';
import shortid from 'shortid';
import checkIfExternalLink from '../../../helpers/checkIfExternalLink';
import generateLink from '../../../helpers/generateLink';
import ImageSelector from '../../Images/ImageSelector';

export default function HardCodedBoxes(props) {
  const {
    options,
    variantName,
    city,
    // configData,
    // businessData,
    poweredImages,
    isMultiLocationSite,
    locationIndex,
    // allGeneral,
    variant,
  } = props;

  const {
    titles,
    subtitles,
    texts,
    dates,
    images,
    ctaLinks,
    ctaNames,
  } = options;

  const getOptionToLoopOver = () => {
    // finds the longest array out of the selected content data below and returns a object key
    const contentData = { titles, subtitles, texts, dates, images, ctaLinks };
    let longestArrayIndex = null;
    let longestArray = 0;

    Object.values(contentData).forEach((content, index) => {
      if (content?.length > longestArray) {
        longestArray = content.length;
        longestArrayIndex = index;
      }
    });
    return Object.keys(contentData)[longestArrayIndex];
  };

  return (
    <>
      {options[getOptionToLoopOver()][locationIndex].map((box, index) => {
        return (
          <Flex
            key={shortid.generate()}
            variant={`${variantName}.box`}
            className='box'
            sx={{
              pointerEvents: ctaLinks ? 'visible' : 'none',
            }}
          >
            {images && (
              <ImageSelector
                className='image'
                variant={`${variantName}.image`}
                image={images[locationIndex][index]} // image from config
                poweredImages={poweredImages} // powered iamges object
                locationIndex={locationIndex}
                alt={`contentbox${index}`}
              />
            )}
            {dates && (
              <Text variant={`${variantName}.date`} as='p' className='date'>
                {dates[locationIndex][index]}
              </Text>
            )}
            {titles && (
              <Heading
                variant={`${variantName}.title`}
                as='h3'
                className='title'
              >
                {titles[locationIndex][index]}
              </Heading>
            )}
            {subtitles && (
              <Heading
                variant={`${variantName}.subtitle`}
                as='h4'
                className='subtitle'
              >
                {subtitles[locationIndex][index]}
              </Heading>
            )}
            {texts && (
              <Text variant={`${variantName}.text`} as='p' className='text'>
                {texts[locationIndex][index]}
              </Text>
            )}
            {ctaLinks && (
              <Link
                key={shortid.generate()}
                variant={`${variantName}.ctaLink`}
                className='ctaLink'
                sx={{
                  pointerEvents: ctaLinks ? 'visible' : 'none',
                }}
                href={
                  ctaLinks
                    ? generateLink(
                        ctaLinks[locationIndex][index],
                        isMultiLocationSite,
                        city
                      )
                    : ''
                }
                target={`${
                  ctaLinks &&
                  checkIfExternalLink(ctaLinks[locationIndex][index])
                    ? '_blank'
                    : ''
                }`}
                rel={`${
                  ctaLinks &&
                  checkIfExternalLink(ctaLinks[locationIndex][index])
                    ? 'noreferrer'
                    : ''
                }`}
              >
                {ctaNames ? ctaNames[locationIndex][index] : 'See more'}
              </Link>
            )}
          </Flex>
        );
      })}
    </>
  );
}
